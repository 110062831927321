
@import url('@/assets/less/mixin.less');
.header-search {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.search-result-modal {
  .mobile-style({
    background: #F5F5F5;
  });
  .total-count {
    font-size: 14px;
    color: #999999;
    line-height: 14px;
    margin-top: 30px;
    margin-bottom: 30px;
    .total-count-num {
      font-weight: 600;
      color: #262626;
    }
  }
  .search-result-content {
    margin-top: 20px;
    .search-item {
      + .search-item {
        margin-top: 20px;
      }
      font-size: 16px;
      font-weight: 600;
      padding-left: 20px;
      &:hover {
        background-color: #5a6fff;
        cursor: pointer;
        span {
          color: white;
        }
      }
      .mobile-style({
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        flex-wrap: wrap;
        padding: 10px 20px;
      });
      > span {
        &:first-child {
          margin-right: 50px;
          width: 200px;
          display: inline-block;
          .mobile-style({
            min-width: 100px;
          });
        }
        .mobile-style({
          width: auto;
        });
      }
      .search-item-keyword {
        color: #5961ff;
      }
    }
  }
}
