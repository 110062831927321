
// ———————————— 页面卡片 ————————————

.page-panel {
  display: block;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(52, 57, 87, 0.1);
  border-radius: 2px;

  + .page-panel {
    margin-top: 20px;
  }
  
  // 卡片头部
  > .page-panel-header {
    display: flex;
    align-items: center;
    padding: 20px;

    // 卡片标题
    > .page-panel-title {
      font-size: 16px;
      font-weight: 600;
      margin-right: 20px;
      white-space: nowrap;
    }

    // 卡片筛选栏（可选）
    > .page-panel-header-filter {

    }

    // 卡片工具栏（右侧图标）
    > .page-panel-tools {

      > .page-panel-tool {

      }
    }
  }

  // 卡片内容
  > .page-panel-content {

    > .data-filter {
      padding: 20px 20px 5px 20px;
    }
  }
}


// ———————————— 单行筛选栏 ————————————

.data-filter-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  // 筛选项
  > .data-filter-item {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;

    + .data-filter-item {
      margin-left: 15px;
    }

    // 筛选项标签
    > .data-filter-item-label {

      + .data-filter-item-value {
        margin-left: 10px;
      }
    }

    // 筛选项内容
    > .data-filter-item-value {
      margin-bottom: 0px;
      margin-left: 0px;
    }
  }

  // 提交按钮
  > .data-filter-btns {
    margin-bottom: 0px;
    margin-left: 15px;
  }
}


// ———————————— 多行筛选栏 ————————————

.data-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  &.cols-2 {

    > .data-filter-btns,
    > .data-filter-item {
      width: calc(50% - 20px);
      
      &.col-full {
        width: calc(100% - 20px);
      }

      &.col-2x {
        width: calc(100% - 20px);
      }
    }
  }

  &.cols-3 {

    > .data-filter-btns,
    > .data-filter-item {
      width: calc(33.333% - 20px);
      
      &.col-full {
        width: calc(100% - 20px);
      }

      &.col-2x {
        width: calc(66.666% - 20px);
      }
    }
  }

  &.cols-4 {

    > .data-filter-btns,
    > .data-filter-item {
      width: calc(24.999% - 20px);
      
      &.col-full {
        width: calc(100% - 20px);
      }

      &.col-2x {
        width: calc(49.999% - 20px);
      }
    }
  }

  &.cols-5 {

    > .data-filter-btns,
    > .data-filter-item {
      width: calc(19.999% - 20px);
      
      &.col-full {
        width: calc(100% - 20px);
      }

      &.col-2x {
        width: calc(39.999% - 20px);
      }
    }
  }

  // 筛选项查询按钮组
  > .data-filter-btns {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 15px;
    margin-left: 20px;

    > button {

      + button {
        margin-left: 15px;
      }
    }
  }

  // 筛选项
  > .data-filter-item {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-left: 20px;

    // 筛选项标签
    > .data-filter-item-label {
      text-align: left;
      flex-grow: 0;
      flex-shrink: 0;
      min-width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      + .data-filter-item-value {
        margin-left: 10px;
      }

      &:after {
        content: ':';
        display: inline-block;
        margin-left: 5px;
      }
    }

    // 筛选项内容
    > .data-filter-item-value {
      margin-bottom: 0px;
      margin-left: 0px;
      flex-grow: 1;
      flex-shrink: 1;

      > .ant-select,
      > .ant-picker {
        width: 100%;
      }
    }
  }
}

// ———————————— 新增编辑界面 ————————————

// 表单项
.form-item-mixin(@colCount) {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% / @colCount - 20px);
  margin-right: 20px;

  &.col-full {
    width: 100%;
  }

  &.col-half {
    width: 49.99%;
  }

  > .ant-form-item-label {
    min-width: 100px;
  }

  > .ant-form-item-control {
    width: 100%;
  }
}

.form-panel-content-mixin() {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 15px;
  flex-shrink: 1;
  flex-grow: 0;
}

.form-panel {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 50px);

  &.cols-2 {
    > .form-panel-content {

      > .form-item {
        .form-item-mixin(2);
      }
    }

    > .ant-form {
      > .form-panel-content {
        > .form-item {
          .form-item-mixin(2);
        }
      }
    }
  }

  &.cols-3 {
    > .form-panel-content {

      > .form-item {
        .form-item-mixin(3);
      }
    }

    > .ant-form {
      > .form-panel-content {
        > .form-item {
          .form-item-mixin(3);
        }
      }
    }
  }

  > .ant-spin {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.45);
    z-index: 9999;
    visibility: hidden;
    transition: 0.12s;

    &.ant-spin-spinning {
      visibility: visible;
    }

    > .ant-spin-text {
      margin-top: 8px;
    }
  }

  > .form-panel-content {
    .form-panel-content-mixin();
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -20px;

    > .form-item {
      .form-item-mixin(1);
    }
  }

  // 表单按钮
  > .form-panel-btns {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #EFEFEF;
    width: 100%;
    padding-top: 20px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  > .ant-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 1;
    flex-grow: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -20px;

    > .form-panel-content {
      .form-panel-content-mixin();

      > .form-item {
        .form-item-mixin(1);
      }
    }
  }
}


// ———————————— 多行筛选栏 ————————————

.filter-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-right: 20px;

  > dl.filter-item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    height: 32px;

    &.filter-item-full {
      width: 100%;
    }

    &.filter-item-half {
      width: 49.99%;
    }

    > dt.filter-item-label {
      position: relative;
      min-width: 120px;
      text-align: right;
      line-height: 31px;
      margin-right: 12px;
    }

    > dd.filter-item-value {
      flex-grow: 1;
      margin-bottom: 0px;
    }
  }

  > .filter-btns {
    width: 100%;
    display: block;
    text-align: center;
  }
}