
&.ant-layout-sider-collapsed {
  .layout-sider {
    .logo {
      width: 60px;
      padding-left: 0px;
      justify-content: center;
      > .logo-img {
        margin-right: 0px;
      }
      > .logo-title {
        display: none;
      }
    }
  }
}
.layout-sider {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  // 图标
  > .logo {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    border-bottom: 1px solid #3b4059;
    padding-bottom: 2px;
    overflow: hidden;
    transition: 0.15s;
    background: linear-gradient(162deg, rgba(44, 49, 76, 0) 6%, #313854 59%, rgba(53, 74, 106, 0.73) 100%),
      linear-gradient(173deg, #33528f 0%, #2c314c 59%, #2c314c 98%);

    > .logo-img {
      float: left;
      width: 38px;
      height: 38px;
      margin: 11px 14px 11px 0;
      border-radius: 10px;
      object-fit: contain;
      background-color: white;
      border-radius: 50%;
      padding: 2px;
      box-shadow: 0px 0px 14px 0px rgba(91, 131, 255, 0.62);
    }

    > .logo-title {
      display: inline-block;
      color: white;
      font-size: 18px;
      white-space: nowrap;
    }
  }

  > .layout-main-menu {
    overflow-y: auto;
  }
}
