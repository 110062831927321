
@import '@/assets/less/variables.less';

#app {
  height: 100%;

  > .ant-layout {
    height: 100%;
  }
}

.ant-layout-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  width: 100%;
  box-shadow: 0px 4px 3px 0px rgba(52, 57, 87, 0.1);
  z-index: 1;

  > .header-panel-collapsed {
    flex-grow: 0;
    flex-shrink: 0;

    > .header-trigger {
      font-size: 18px;
      color: #777777;
      padding: 10px;
      margin-left: 15px;
      border-radius: 4px;
      transition: 0.12s;

      &:hover {
        background-color: #f2f2f2;
        color: @primary-color;
      }
    }
  }

  > .neu-page-tabs {
    flex-grow: 1;
    flex-shrink: 1;
    width: 0px;
  }

  // 快捷菜单
  > .layout-shortcut-menu {
    flex-shrink: 0;
    flex-grow: 0;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;

    > .layout-userinfo {
      display: inline-block;
      line-height: 40px;
      color: #333333;
      font-size: 16px;

      > img {
        width: 40px;
        margin-left: 10px;
        border-radius: 50%;
      }
    }
  }
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-layout {
  position: relative;

  > .ant-layout-content {
    overflow-y: auto;
    padding: 20px;

    > .ant-tabs {
      > .ant-tabs-nav {
        margin-bottom: 0px;
      }

      > .ant-tabs-content-holder {
        background-color: white;
      }
    }
  }
}

.layout-header-tools {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 25px;
  height: 32px;

  > button,
  > span {
    + button,
    + span {
      margin-left: 15px;
    }
  }
}

.label-welcome {
  color: white;
  flex-grow: 1;
  text-align: right;
  padding-right: 20px;
}
